import Header, { HeaderProps } from "components/layout/Header/Header";
import Footer, { FooterProps } from "components/layout/Footer/Footer";
import styles from "./Layout.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import { AlertBannerProps } from "components/layout/AlertBanner/AlertBanner";
import Breadcrumbs, {
  BreadcrumbsProps,
} from "components/layout/Breadcrumbs/Breadcrumbs";
import { BypassBannerProps } from "components/layout/BypassBanner/BypassBanner";
import { SiteContext } from "../../utils/useSite";
import { ThemeContext } from "../../utils/useTheme";
import { IReactComponentProps } from "src/base-props/IReactComponentProps";
import * as Components from "../../IMDI.Components";
import "./Layout.css";

export interface IMDIComponent extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component: IReactComponentProps;
}

export interface LayoutProps extends ReactComponentPropsBase {
  alertBanner?: AlertBannerProps;
  bypassBanner?: BypassBannerProps;
  header?: HeaderProps;
  footer?: FooterProps;
  component: IMDIComponent;
  epiFullRefreshPropertyNames?: string[];
  breadcrumbs?: BreadcrumbsProps;
  theme?: "white" | "dark";
}

export const Layout = ({
  alertBanner,
  bypassBanner,
  header,
  footer,
  component,
  epiFullRefreshPropertyNames,
  breadcrumbs,
  theme,
}: LayoutProps) => {
  const Comp = Components[component.componentName];

  return (
    <SiteContext.Provider value="imdi">
      <ThemeContext.Provider value={theme ? theme : "white"}>
        <div
          className={classNames(styles.layout, theme ? styles[theme] : null)}
        >
          <div className="block-display">
            <div className={classNames(styles.headerContainer)}>
              {header && (
                <Header
                  {...header}
                  alertBanner={alertBanner}
                  bypassBanner={bypassBanner}
                />
              )}
              {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
            </div>

            {Comp && (
              <div id="page-content">
                {/* @ts-ignore */}
                <Comp {...component.component} />
              </div>
            )}
          </div>
          {footer && <Footer {...footer} />}
          {epiFullRefreshPropertyNames && (
            <input
              type="hidden"
              data-epi-full-refresh-property-names={epiFullRefreshPropertyNames?.join(
                ","
              )}
            />
          )}
        </div>
      </ThemeContext.Provider>
    </SiteContext.Provider>
  );
};

export default Layout;
